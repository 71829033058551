var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"localization-overview pa-0",attrs:{"fluid":"","fill-height":"","data-test-id":"localizationOverview"}},[_c('div',{staticClass:"localization-content"},[(!_vm.initialLoading)?_c('Toolbar',{key:_vm.reloadFilters,attrs:{"search-props":{
        possibleFilters: _vm.possibleFilters,
        disableFullTextSearch: true,
      }},on:{"update-filters":_vm.updateFilter},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"mx-2 white--text",attrs:{"outlined":!_vm.hasChanges && !_vm.showMinified,"color":"green","data-test-id":"saveAllLocalizationsBtn","icon":_vm.showMinified,"disabled":_vm.runningAction},on:{"click":_vm.saveLocalizations}},[(_vm.showMinified)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.hasChanges ? "mdi-content-save" : "mdi-content-save-outline")+" ")]):_c('div',[_vm._v("Save All")])],1),_c('v-btn',{attrs:{"color":"primary","data-test-id":"newLocalizationBtn","title":"Add new localization","disabled":_vm.runningAction,"fab":_vm.showMinified,"small":_vm.showMinified},on:{"click":_vm.addLocalization}},[(_vm.showMinified)?_c('v-icon',[_vm._v("mdi-plus")]):_c('div',[_vm._v("New Localization")])],1)]},proxy:true}],null,false,3232624735),model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column pt-3"},[_c('v-form',{ref:"localizationForm",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-data-table',{staticClass:"localizations-table",attrs:{"disable-pagination":"","hide-default-footer":"","fixed-header":"","single-select":"","multi-sort":"","data-test-id":"localizationsTable","item-class":function (item) { return (item.changed ? 'localization-changed' : '') +
              ' localization-row'; },"options":_vm.options,"items":_vm.localizations,"headers":_vm.headers,"height":_vm.tableHeight,"server-items-length":_vm.total,"no-data-text":_vm.noLocalizationText,"no-results-text":_vm.noLocalizationText,"disable-sort":_vm.runningAction,"loading":_vm.runningAction},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.localizations",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center mr-2"},[_vm._v(_vm._s(header.text))]),(_vm.localizationConfig)?_c('v-combobox',{staticClass:"locale-select",attrs:{"dense":"","outlined":"","hide-details":"auto","data-test-id":"localizationLocaleSelect","disabled":_vm.runningAction,"items":_vm.localizationConfig.supportedLocales,"rules":[function (val) { return _vm.$isValidLocale(val) || 'Invalid locale'; }]},model:{value:(_vm.shownLocale),callback:function ($$v) {_vm.shownLocale=$$v},expression:"shownLocale"}}):_vm._e()],1)]}},{key:"item.i18nKey",fn:function(ref){
              var item = ref.item;
return [(!item.isNew)?_c('div',{class:{
                'font-weight-bold': Boolean(item.changed),
              }},[_vm._v(" "+_vm._s(item.i18nKey + (item.changed ? "*" : ""))+" ")]):_vm._e()]}},{key:"item.localizations",fn:function(ref){
              var item = ref.item;
return [_c('div',{ref:'localizations_' + item.id,staticClass:"localizations-container",attrs:{"data-test-id":'i18n_editor_' +
                item.i18nKey +
                '_' +
                item.referenceKey +
                '_' +
                item.referenceKeySpace,"tabindex":"-1"},on:{"focusout":function($event){return _vm.handleFocusOut('localizations_' + item.id)}}},[(_vm.localizationConfig)?_c('I18nEditor',{key:item.i18nKey + _vm.shownLocale,attrs:{"domain":_vm.selectedDomain,"uimodel":{
                  path: item.i18nKey,
                  data: item.valuesMap,
                },"disabled":_vm.runningAction,"configuration":_vm.localizationConfig,"first-locale":_vm.shownLocale},on:{"input":function($event){return _vm.updateLocalizations(item, $event)}}}):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(item.changed)?_c('v-btn',{attrs:{"icon":"","tabindex":"-1","disabled":_vm.runningAction,"data-test-id":'localization_restore_btn_' +
                  item.i18nKey +
                  '_' +
                  item.referenceKey +
                  '_' +
                  item.referenceKeySpace},on:{"click":function($event){return _vm.restoreLocalization(item)}}},[_c('v-icon',[_vm._v("mdi-restore")])],1):_vm._e(),_c('v-btn',{class:{
                  'ml-10': !item.changed,
                  'ml-1': item.changed,
                },attrs:{"icon":"","color":"red","tabindex":"-1","data-test-id":'localization_remove_btn_' +
                  item.i18nKey +
                  '_' +
                  item.referenceKey +
                  '_' +
                  item.referenceKeySpace,"disabled":_vm.runningAction},on:{"click":function($event){return _vm.deleteLocalization(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"body.append",fn:function(){return [_vm._l((_vm.newLocalizations),function(item,index){return _c('tr',{key:index,attrs:{"data-test-id":'new_localization_' + index}},[_c('td',[_c('v-text-field',{ref:'i18nKey_input_' + item.id,refInFor:true,attrs:{"dense":"","outlined":"","hide-details":"auto","placeholder":"Key","disabled":_vm.runningAction,"rules":[_vm.newLocalizationInputRules.required],"data-test-id":"i18nKeyInput"},model:{value:(item.i18nKey),callback:function ($$v) {_vm.$set(item, "i18nKey", $$v)},expression:"item.i18nKey"}})],1),_c('td',[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"auto","placeholder":"Area","items":_vm.keySpaces,"disabled":_vm.runningAction,"rules":[_vm.newLocalizationInputRules.required],"menu-props":{
                    contentClass: 'referenceKeySpaceSelectMenu',
                  },"data-test-id":"referenceKeySpaceInput"},model:{value:(item.referenceKeySpace),callback:function ($$v) {_vm.$set(item, "referenceKeySpace", $$v)},expression:"item.referenceKeySpace"}})],1),_c('td',[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","placeholder":"Area Pattern","disabled":_vm.runningAction,"rules":[_vm.newLocalizationInputRules.required],"data-test-id":"referenceKeyInput"},model:{value:(item.referenceKey),callback:function ($$v) {_vm.$set(item, "referenceKey", $$v)},expression:"item.referenceKey"}})],1),_c('td',{class:{
                  'localizations-column': true,
                  disabled: !Boolean(item.i18nKey),
                }},[_c('div',{ref:'localizations_' + item.id,refInFor:true,staticClass:"localizations-container",attrs:{"data-test-id":'i18n_editor_' + item.i18nKey,"tabindex":"-1"},on:{"focusout":function($event){return _vm.handleFocusOut('localizations_' + item.id)}}},[_c('I18nEditor',{key:item.i18nKey + _vm.shownLocale,attrs:{"domain":_vm.selectedDomain,"uimodel":{
                      path: item.i18nKey,
                      data: item.valuesMap,
                    },"configuration":_vm.localizationConfig,"first-locale":_vm.shownLocale,"disabled":!Boolean(item.i18nKey) || _vm.runningAction},on:{"input":function($event){return _vm.updateLocalizations(item, $event)}}})],1)]),_c('td',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ml-10",attrs:{"icon":"","color":"red","tabindex":"-1","data-test-id":"newLocalizationRemoveBtn","disabled":_vm.runningAction},on:{"click":function($event){return _vm.deleteLocalization(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])])}),_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{staticClass:"px-0",attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('PaginationComponent',{attrs:{"hide-page-input":"","text-buttons":"","disabled":_vm.runningAction},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])]},proxy:true}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }