<template>
  <v-container
    fluid
    fill-height
    class="localization-overview pa-0"
    data-test-id="localizationOverview"
  >
    <div class="localization-content">
      <Toolbar
        v-if="!initialLoading"
        v-model="filterQuery"
        :search-props="{
          possibleFilters,
          disableFullTextSearch: true,
        }"
        :key="reloadFilters"
        @update-filters="updateFilter"
      >
        <template #actions>
          <v-btn
            :outlined="!hasChanges && !showMinified"
            color="green"
            class="mx-2 white--text"
            data-test-id="saveAllLocalizationsBtn"
            :icon="showMinified"
            :disabled="runningAction"
            @click="saveLocalizations"
          >
            <v-icon v-if="showMinified">
              {{ hasChanges ? "mdi-content-save" : "mdi-content-save-outline" }}
            </v-icon>
            <div v-else>Save All</div>
          </v-btn>

          <v-btn
            color="primary"
            data-test-id="newLocalizationBtn"
            title="Add new localization"
            :disabled="runningAction"
            :fab="showMinified"
            :small="showMinified"
            @click="addLocalization"
          >
            <v-icon v-if="showMinified">mdi-plus</v-icon>
            <div v-else>New Localization</div>
          </v-btn>
        </template>
      </Toolbar>
      <div class="d-flex flex-column pt-3">
        <v-form @submit.prevent ref="localizationForm">
          <v-data-table
            disable-pagination
            hide-default-footer
            fixed-header
            single-select
            multi-sort
            data-test-id="localizationsTable"
            class="localizations-table"
            :item-class="
              (item) =>
                (item.changed ? 'localization-changed' : '') +
                ' localization-row'
            "
            :options.sync="options"
            :items="localizations"
            :headers="headers"
            :height="tableHeight"
            :server-items-length="total"
            :no-data-text="noLocalizationText"
            :no-results-text="noLocalizationText"
            :disable-sort="runningAction"
            :loading="runningAction"
          >
            <!-- eslint-disable-next-line -->
            <template #header.localizations="{ header }">
              <div class="d-flex align-center">
                <div class="d-flex align-center mr-2">{{ header.text }}</div>
                <v-combobox
                  v-if="localizationConfig"
                  v-model="shownLocale"
                  dense
                  outlined
                  hide-details="auto"
                  class="locale-select"
                  data-test-id="localizationLocaleSelect"
                  :disabled="runningAction"
                  :items="localizationConfig.supportedLocales"
                  :rules="[(val) => $isValidLocale(val) || 'Invalid locale']"
                />
              </div>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.i18nKey="{ item }">
              <div
                v-if="!item.isNew"
                :class="{
                  'font-weight-bold': Boolean(item.changed),
                }"
              >
                {{ item.i18nKey + (item.changed ? "*" : "") }}
              </div>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.localizations="{ item }">
              <!-- 
                Defining the tabindex is needed, so that the :focus-within pseudo selector 
                works correctly
              -->
              <div
                :ref="'localizations_' + item.id"
                class="localizations-container"
                :data-test-id="
                  'i18n_editor_' +
                  item.i18nKey +
                  '_' +
                  item.referenceKey +
                  '_' +
                  item.referenceKeySpace
                "
                tabindex="-1"
                @focusout="handleFocusOut('localizations_' + item.id)"
              >
                <I18nEditor
                  v-if="localizationConfig"
                  :key="item.i18nKey + shownLocale"
                  :domain="selectedDomain"
                  :uimodel="{
                    path: item.i18nKey,
                    data: item.valuesMap,
                  }"
                  :disabled="runningAction"
                  :configuration="localizationConfig"
                  :first-locale="shownLocale"
                  @input="updateLocalizations(item, $event)"
                />
              </div>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <div class="d-flex justify-end">
                <v-btn
                  v-if="item.changed"
                  icon
                  tabindex="-1"
                  :disabled="runningAction"
                  :data-test-id="
                    'localization_restore_btn_' +
                    item.i18nKey +
                    '_' +
                    item.referenceKey +
                    '_' +
                    item.referenceKeySpace
                  "
                  @click="restoreLocalization(item)"
                >
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
                <v-btn
                  :class="{
                    'ml-10': !item.changed,
                    'ml-1': item.changed,
                  }"
                  icon
                  color="red"
                  tabindex="-1"
                  :data-test-id="
                    'localization_remove_btn_' +
                    item.i18nKey +
                    '_' +
                    item.referenceKey +
                    '_' +
                    item.referenceKeySpace
                  "
                  :disabled="runningAction"
                  @click="deleteLocalization(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>

            <!-- eslint-disable-next-line -->
            <template #body.append>
              <!-- New localizations are appended to the table -->
              <tr
                v-for="(item, index) in newLocalizations"
                :key="index"
                :data-test-id="'new_localization_' + index"
              >
                <td>
                  <v-text-field
                    v-model="item.i18nKey"
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="Key"
                    :ref="'i18nKey_input_' + item.id"
                    :disabled="runningAction"
                    :rules="[newLocalizationInputRules.required]"
                    data-test-id="i18nKeyInput"
                  />
                </td>
                <td>
                  <v-select
                    v-model="item.referenceKeySpace"
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="Area"
                    :items="keySpaces"
                    :disabled="runningAction"
                    :rules="[newLocalizationInputRules.required]"
                    :menu-props="{
                      contentClass: 'referenceKeySpaceSelectMenu',
                    }"
                    data-test-id="referenceKeySpaceInput"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="item.referenceKey"
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="Area Pattern"
                    :disabled="runningAction"
                    :rules="[newLocalizationInputRules.required]"
                    data-test-id="referenceKeyInput"
                  />
                </td>
                <td
                  :class="{
                    'localizations-column': true,
                    disabled: !Boolean(item.i18nKey),
                  }"
                >
                  <div
                    class="localizations-container"
                    :ref="'localizations_' + item.id"
                    :data-test-id="'i18n_editor_' + item.i18nKey"
                    tabindex="-1"
                    @focusout="handleFocusOut('localizations_' + item.id)"
                  >
                    <I18nEditor
                      :key="item.i18nKey + shownLocale"
                      :domain="selectedDomain"
                      :uimodel="{
                        path: item.i18nKey,
                        data: item.valuesMap,
                      }"
                      :configuration="localizationConfig"
                      :first-locale="shownLocale"
                      :disabled="!Boolean(item.i18nKey) || runningAction"
                      @input="updateLocalizations(item, $event)"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-end">
                    <v-btn
                      icon
                      color="red"
                      tabindex="-1"
                      class="ml-10"
                      data-test-id="newLocalizationRemoveBtn"
                      :disabled="runningAction"
                      @click="deleteLocalization(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
              <tr class="v-data-table__empty-wrapper">
                <td :colspan="headers.length" class="px-0">
                  <div class="d-flex">
                    <v-spacer />
                    <!-- CUSTOM PAGINATION COMPONENT -->
                    <PaginationComponent
                      v-model="currentPage"
                      hide-page-input
                      text-buttons
                      :disabled="runningAction"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import Toolbar from "../common/templates/Toolbar";
import PaginationComponent from "../PaginationComponent";
import I18nEditor from "../configuration/data/editors/specific/I18nEditor";
import configServiceMixin from "../../mixins/config-service-mixin";
import mainOverviewMixin from "../../mixins/main-overview-mixin";

const noLocalizationText = "No localizations found";

export default {
  mixins: [configServiceMixin, mainOverviewMixin],
  inject: {
    getPageHeight: {
      default: () => {
        console.warn("Parent component does not provide method getPageHeight");
      },
    },
  },
  components: {
    Toolbar,
    PaginationComponent,
    I18nEditor,
  },

  data() {
    return {
      localizations: [],
      localizationConfig: undefined,
      currentPage: 1,
      total: 0,
      filters: {},
      options: {
        sortBy: ["i18nKey", "referenceKeySpace"],
        sortDesc: [false, false],
      },
      shownLocale: undefined,
      savedLocalizations: {},
      changedLocalizations: {},
      filterQuery: null,
      newLocalizations: [],
      keySpaces: [],
      loadLocalizationsController: null,
      reloadFilters: 0,
      initialLoading: false,
      runningAction: false,
    };
  },

  created() {
    this.noLocalizationText = noLocalizationText;
    const filter = this.$route.query?.filter;
    if (filter) this.filterQuery = JSON.parse(this.$urlDecode(filter));
    this.runningAction = true;
    this.initialLoading = true;
    Promise.all([this.loadKeySpaces(), this.loadLocales()]).then(() => {
      this.initialLoading = false;
      this.$nextTick(() => {
        this.init();
        //Create query watcher here, so it does nottrigger multiple calls to
        //the lookup endpoint on create
        this.$watch(
          "$route.query",
          (newQuery, oldQuery) => {
            const queryChanged = !this.$isEqual(newQuery, oldQuery);
            if (queryChanged && this.$route.name === "localizations") {
              //the query consists of filters, page and sorting so reload
              //the localizations if one of those changes
              this.init();
            }
          },
          { deep: true }
        );
      });
      this.runningAction = false;
    });
  },

  async beforeRouteUpdate(to, from, next) {
    //set meta data if domain has been changed
    const toDomain = to.path.split("/")[1];
    const fromDomain = from.path.split("/")[1];
    const domainChange = toDomain !== fromDomain;
    this.$set(to.meta, "domainChange", domainChange);
    if (domainChange && this.hasChanges) {
      const confirmed = await this.$confirm(
        "Unsaved changes",
        "Leave current context? Unsaved changes will be lost!"
      );
      next(confirmed);
      return;
    }
    next();
  },

  async beforeRouteLeave(from, to, next) {
    let confirmed = true;
    if (this.hasChanges) {
      confirmed = await this.$confirm(
        "Discard unsaved changes?",
        "Leave current context? Unsaved changes will be lost!"
      );
    }
    next(confirmed);
  },

  watch: {
    filters: {
      handler: function (filters) {
        const domainChange = this.$route.meta.domainChange;
        let query = Object.assign({}, this.$route.query);
        const oldFilterQuery = query.filter;
        //remove filter query if the query is faulty
        if (Object.keys(filters).length > 0) {
          let filterQuery = this.filterQuery;
          if (this.$isPlainObject(filterQuery))
            filterQuery = JSON.stringify(filterQuery);
          query.filter = this.$urlEncode(filterQuery);
        } else {
          delete query.filter;
        }

        //do not reload if the filter did not change
        if (oldFilterQuery === query.filter) return;

        //if the filters were reloaded after a domain change, do not change
        //the current page, since the user might want to view the translations in the
        //same context
        if (!domainChange) query.page = "1";
        let route = Object.assign({}, this.$route);
        this.$delete(route.meta, "domainChange");
        this.$set(route, "query", query);
        this.$router.replace(route);

        //set the current page after the query, so that the currentPage watcher
        //does not trigger a localizations reload
        if (!domainChange) this.currentPage = 1;
        this.changedLocalizations = {};
      },
      deep: true,
    },

    currentPage(page) {
      const queryValue = this.$route.query?.page;
      //if the page is already set in the query, do not trigger a reload
      if (queryValue && Number(queryValue) === page) return;
      const query = Object.assign({}, this.$route.query, { page: "" + page });
      this.$router.push({ query });
    },

    options(options) {
      //if the watcher was triggered by a domain change
      //prevent the options from being changed.
      if (this.$route.meta.domainChange) {
        this.$delete(this.$route.meta, "domainChange");
        return;
      }

      let query = Object.assign({}, this.$route.query);
      const sortBy = options.sortBy;
      const sortDesc = options.sortDesc;
      let sort = "";

      //build sort url parameter value
      if (Array.isArray(sortBy)) {
        for (let i = 0; i < sortBy.length; i++) {
          const sortQuery = (sortDesc[i] ? "-" : "") + sortBy[i];
          if (!sort) sort = sortQuery;
          else sort += "," + sortQuery;
        }
      }

      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);

      this.$router.push({ query });
    },

    changedLocalizations: {
      handler: function (changed) {
        //cleanup the stored changes object and remove
        //unchanged/deleted localizations

        //isEmpty checks if the object is completely empty
        //returns false if given object is NOT of type object
        const isEmpty = (obj) => {
          if (!this.$isPlainObject(obj)) return false;
          return (
            Object.keys(obj).length === 0 ||
            Object.values(obj).every((child) => isEmpty(child))
          );
        };

        //cleanObject function removes every empty child object from the given object
        const cleanObject = (obj) => {
          if (this.$isPlainObject(obj)) {
            Object.keys(obj).forEach((key) => {
              if (isEmpty(obj[key])) this.$delete(obj, key);
              else Object.keys(obj).forEach((key) => cleanObject(obj[key]));
            });
          }
        };

        cleanObject(changed);
      },
      deep: true,
    },
  },

  methods: {
    async init() {
      //initialize the component with the route parameters
      const namedRoute = this.$route.name;

      if (namedRoute === "localizations") {
        //get the query parameters and update the according component variables
        const routeQuery = this.$route.query;
        let page = routeQuery.page;
        let filter = routeQuery.filter;
        let sort = routeQuery.sort;

        if (page) {
          page = parseInt(page, 10);
          if (Number.isNaN(page)) page = 1;
          this.currentPage = page;
        }

        if (filter) {
          try {
            filter = this.$urlDecode(filter);
            this.filterQuery = JSON.parse(filter);
          } catch (e) {
            this.filterQuery = null;
            console.warn(e);
          }
        }

        if (sort) {
          const sortBy = [];
          const sortDesc = [];
          const sortQuery = sort.split(",");

          const addToSorted = (sortString) => {
            //this function parses the given string and adds the
            //sorting information to the respective arrays
            const isDesc = sortString.startsWith("-");
            const property = isDesc ? sortString.substring(1) : sortString;
            const isSortableField = this.headers.some(
              (header) => header.value === property && header.sortable !== false
            );
            if (isSortableField) {
              sortBy.push(property);
              sortDesc.push(isDesc);
            }
          };

          if (Array.isArray(sortQuery))
            sortQuery.forEach((part) => addToSorted(part));
          else addToSorted(sortQuery);
          this.options = Object.assign(this.options, { sortBy, sortDesc });
        }

        let query = {};
        const hasSort =
          this.options.sortBy?.length > 0 && this.options.sortDesc?.length > 0;
        if (this.currentPage) query.page = "" + this.currentPage;
        if (this.filterQuery && this.filterQuery.bool.filter.length > 0) {
          let filterQuery = this.filterQuery;
          if (this.$isPlainObject(filterQuery)) {
            filterQuery = JSON.stringify(filterQuery);
          }
          query.filter = this.$urlEncode(filterQuery);
        }
        if (hasSort) query.sort = sort;

        this.$router.replace({
          name: "localizations",
          query,
        });
        //update the URL query
        this.$nextTick(() => this.loadLocalizations());
      }
    },

    async loadKeySpaces() {
      const keySpaces =
        await this.$store.$coreApi.coreLocalizationApi.getLocalizationKeySpaces(
          this.$store.state.selectedDomain
        );
      this.keySpaces = keySpaces ?? [];
      this.keySpaces.sort((ks1, ks2) => ks1.localeCompare(ks2));
    },

    async loadLocales() {
      //load supportedLocales and fallbackLocale
      const merged =
        await this.$store.$coreApi.coreConfigurationApi.getMergedData(
          this.selectedDomain,
          "Configuration",
          "core.configService"
        );
      const configData = merged?.data;
      this.localizationConfig = this.$getObjectValueByPath(
        configData,
        "localization"
      );
      this.shownLocale = this.localizationConfig?.fallbackLocale;
    },

    async loadLocalizations() {
      let aborted = false;
      try {
        if (this.runningAction && this.loadLocalizationsController) {
          //Abort the already running request before starting a new one
          this.loadLocalizationsController.abort();
        }

        this.runningAction = true;
        const offset = (this.currentPage - 1) * this.limit;
        const body = {
          limit: this.limit,
          offset,
          filter: {},
          sort: [],
        };

        for (let index in this.filters) {
          const filter = this.filters[index];
          const property = filter.property;
          const value = filter.value;
          body.filter[property] = {
            [filter.operator]: value,
          };
        }

        //add sorting
        const sortBy = this.options?.sortBy;
        const sortDesc = this.options?.sortDesc;
        if (Array.isArray(sortBy)) {
          for (let i = 0; i < sortBy.length; i++) {
            body.sort.push({
              property: sortBy[i],
              desc: sortDesc[i] ?? false,
            });
          }
        }

        //Create new abort controller to enable to possibility of aborting requests
        //Requests will be aborted, if another load request is called, while the current one is
        //still running
        const controller = new AbortController();
        const signal = controller.signal;
        this.loadLocalizationsController = controller;

        const localizations =
          await this.$store.$coreApi.coreLocalizationApi.getLocalizations(
            this.selectedDomain,
            body,
            signal
          );

        if (localizations.aborted) {
          aborted = true;
          this.loadLocalizationsController = null;
          return;
        }

        this.localizations = localizations?.result ?? [];
        this.total = localizations?.count ?? 0;

        this.localizations.forEach((localization) => {
          const reference =
            localization.referenceKeySpace + ":" + localization.referenceKey;
          const i18nKey = localization.i18nKey;

          //update the savedLocalization object with the current values from the database
          if (!this.savedLocalizations[reference]) {
            this.$set(this.savedLocalizations, reference, {});
          }
          if (!this.savedLocalizations[reference][i18nKey]) {
            this.$set(
              this.savedLocalizations[reference],
              i18nKey,
              this.$cloneObject(localization.valuesMap)
            );
          }

          //if the localizations were changed by the user, updated the localizations
          //with the changed values
          const changedLocalization =
            this.changedLocalizations?.[reference]?.[i18nKey];
          if (changedLocalization) {
            let valuesMap = localization.valuesMap;
            Object.keys(changedLocalization).forEach((locale) => {
              this.$set(valuesMap, locale, {
                value: changedLocalization[locale],
                persisted: true,
              });
            });
            this.$set(localization, "changed", true);
          }

          this.$set(localization, "id", this.$uuid.v4());
        });
      } finally {
        if (!aborted) this.runningAction = false;
      }
    },

    async deleteLocalization(item) {
      const id = item.id;
      if (item.isNew) {
        const idx = this.newLocalizations.findIndex((loc) => loc.id === id);
        this.$delete(this.newLocalizations, idx);
        const reference = item.referenceKeySpace + ":" + item.referenceKey;
        if (this.changedLocalizations[reference]) {
          this.$delete(this.changedLocalizations[reference], item.i18nKey);
        }
        return;
      }

      const confirmed = await this.$confirm(
        "Delete localization?",
        "Are you sure you want to delete " +
          item.i18nKey +
          " ? This cannot be reverted."
      );
      if (!confirmed) return;

      try {
        this.runningAction = true;
        const response =
          await this.$store.$coreApi.coreLocalizationApi.deleteLocalization(
            this.selectedDomain,
            item.referenceKeySpace,
            item.referenceKey,
            [item.i18nKey]
          );
        if (response?.ok) {
          const reference = item.referenceKeySpace + ":" + item.referenceKey;
          if (this.changedLocalizations[reference]) {
            this.$delete(this.changedLocalizations[reference], item.i18nKey);
          }
          await this.loadLocalizations();
        }
      } finally {
        this.runningAction = false;
      }
    },

    updateLocalizations(item, updatedValues) {
      //Update the values map of the given item
      const valuesMap = this.$cloneObject(item.valuesMap);
      const reference = item.referenceKeySpace + ":" + item.referenceKey;
      const savedItem = this.savedLocalizations?.[reference]?.[item.i18nKey];

      Object.keys(updatedValues).forEach((locale) => {
        const localization = updatedValues[locale];
        const savedLocalization = savedItem?.[locale]?.value;
        if (
          (localization === null || localization === "") &&
          !savedLocalization
        ) {
          //if the localization was deleted, remove it from the values map
          this.$delete(item.valuesMap, locale);
        } else {
          //if the locale does not exist in the current values map
          //or if the localization variable is not of type object (which means
          //that it was changed in the i18nEditor) update the locale
          if (!valuesMap[locale] || !this.$isPlainObject(localization)) {
            this.$set(item.valuesMap, locale, {
              value: localization,
              persisted: true,
            });
          }
        }
      });

      const hasChanged =
        !item.isNew && !this.$isEqual(item.valuesMap, savedItem);
      //extract the differences between the current localizations and the ones saved on the server
      //to store them later on
      const i18nKey = item.i18nKey;
      if (hasChanged) {
        Object.keys(item.valuesMap).forEach((locale) => {
          const localization = item.valuesMap[locale];
          const savedLocalization = savedItem?.[locale];

          //if the localizations differ, add the locale and localization to the changedLocalizations object
          //if they are the same, remove it from the changedLocalizations object
          if (!this.$isEqual(localization, savedLocalization)) {
            if (!this.changedLocalizations[reference]) {
              this.$set(this.changedLocalizations, reference, {});
            }

            if (!this.changedLocalizations[reference][i18nKey]) {
              this.$set(this.changedLocalizations[reference], i18nKey, {});
            }
            this.$set(
              this.changedLocalizations[reference][i18nKey],
              locale,
              localization.value
            );
          } else if (this.changedLocalizations?.[reference]?.[i18nKey]) {
            this.$delete(this.changedLocalizations[reference][i18nKey], locale);
          }
        });
      } else if (this.changedLocalizations[reference]) {
        //if the items localizations are not changed, remove it from
        //the changedLocalizations object
        this.$delete(this.changedLocalizations[reference], i18nKey);
      }
      this.$set(item, "changed", hasChanged);
    },

    restoreLocalization(item) {
      //restore the items localizations
      const reference = item.referenceKeySpace + ":" + item.referenceKey;
      const savedLocalization =
        this.savedLocalizations?.[reference]?.[item.i18nKey];
      this.$delete(this.changedLocalizations[reference], item.i18nKey);
      this.$set(item, "valuesMap", this.$cloneObject(savedLocalization));
      this.$set(item, "changed", false);
    },

    async updateFilter(filters) {
      //wait until the next tick, so that the filterQuery variable has been changed
      this.$nextTick(async function () {
        //get the new filter from the filterQuery variable and the old filter from the
        //URL query params
        const queryFilter = this.$route.query?.filter;
        const oldFilter = queryFilter
          ? JSON.parse(this.$urlDecode(queryFilter))
          : null;

        const filterChanged =
          !this.$isEqual(oldFilter, this.filterQuery) &&
          this.filterQuery.bool.filter.length > 0;

        //if there are localization changes, and the filter have been changed
        //display confirm dialog if changes should be discarded
        if (
          this.hasChanges &&
          filterChanged &&
          !(await this.$confirm(
            "Discard unsaved changes?",
            "You have unsaved changes, changing filters will discard them! "
          ))
        ) {
          //Abort the filter update. Set the filterQuery to the previous filter and
          //reload the search bar, so that the previous filters boxes are displayed
          this.filterQuery = oldFilter;
          this.$nextTick(() => this.reloadFilters++);
          return;
        }
        this.filters = this.$cloneObject(filters);
      });
    },

    addLocalization() {
      const id = this.$uuid.v4();
      const localization = {
        id,
        valuesMap: {},
        isNew: true,
      };

      //check if there are currently any filters set, if yes
      //add the values to the associated field of the new localization
      const filters = Object.values(this.filters);
      let i18nKey = "";
      const i18nKeyFilter = filters.find((ks) => ks.property === "i18nKey");
      if (i18nKeyFilter) i18nKey = i18nKeyFilter.value;
      this.$set(localization, "i18nKey", i18nKey);

      let referenceKeySpace = "*";
      const keySpaceFilter = filters.find(
        (ks) => ks.property === "referenceKeySpace"
      );
      if (keySpaceFilter) referenceKeySpace = keySpaceFilter.value;
      this.$set(localization, "referenceKeySpace", referenceKeySpace);

      let referenceKey = "*";
      const referenceKeyFilter = filters.find(
        (ks) => ks.property === "referenceKey"
      );
      if (referenceKeyFilter) referenceKey = referenceKeyFilter.value;
      this.$set(localization, "referenceKey", referenceKey);

      this.newLocalizations.push(localization);
      this.$nextTick(() => {
        //focus the i18nKey input of the new localization
        const element = this.$refs["i18nKey_input_" + id][0];
        element.focus();
      });
    },

    validate() {
      const form = this.$refs.localizationForm;
      if (!this.$validateVForm(form)) {
        return false;
      }
      return true;
    },

    async saveLocalizations() {
      try {
        if (!this.validate()) return;
        this.runningAction = true;
        if (await this.newHaveDuplicates()) return;

        //add all new localization to the changedLocalizations object,
        //so that they get saved correctly
        this.newLocalizations.forEach((localization) => {
          this.$set(localization, "isNew", false);
          this.updateLocalizations(localization, localization.valuesMap);
        });

        //Build a request for each reference (reference = keySpace + key) and
        //add all changed translations as body
        const changedLocalizations = this.$cloneObject(
          this.changedLocalizations
        );

        for (let reference in changedLocalizations) {
          const referenceParts = reference.split(":");
          const referenceKeySpace = referenceParts[0];
          const referenceKey = referenceParts[1];
          //build the localizations array for the current reference
          let localizations = [];
          Object.keys(changedLocalizations[reference]).forEach((i18nKey) => {
            //call the updateTranslations method from the config service mixin component
            //to format the translations correctly for saving and add them in the localizations array
            localizations = this.updateTranslations(
              i18nKey,
              changedLocalizations[reference][i18nKey],
              localizations
            );
          });
          const response =
            await this.$store.$coreApi.coreLocalizationApi.patchLocalizations(
              this.selectedDomain,
              localizations,
              referenceKeySpace,
              referenceKey
            );
          if (response?.ok) {
            //if the request was successfull,
            //remove saved localizations from newLocalizations object
            const idx = this.newLocalizations.findIndex((newLocalization) => {
              return (
                newLocalization.referenceKey === referenceKey &&
                newLocalization.referenceKeySpace === referenceKeySpace &&
                Object.keys(changedLocalizations[reference]).some(
                  (i18nKey) => newLocalization.i18nKey === i18nKey
                )
              );
            });
            if (idx >= 0) this.$delete(this.newLocalizations, idx);
            //remove change markers for the currently saved reference
            //also clear savedLocalizations for this reference, since they get rebuild
            //after reload
            this.$delete(this.changedLocalizations, reference);
            this.$delete(this.savedLocalizations, reference);
          }
        }
      } finally {
        this.runningAction = false;
      }
      await this.loadLocalizations();
    },

    async newHaveDuplicates() {
      //This method checks if newly added localizations have already peristed duplicates
      for (let localization of this.newLocalizations) {
        const body = {
          offset: 0,
          limit: 1,
          filter: {
            i18nKey: {
              eq: localization.i18nKey,
            },
            referenceKeySpace: {
              eq: localization.referenceKeySpace,
            },
            referenceKey: {
              eq: localization.referenceKey,
            },
          },
        };

        const duplicate =
          await this.$store.$coreApi.coreLocalizationApi.getLocalizations(
            this.selectedDomain,
            body
          );

        if (duplicate.count > 0) {
          const localizations = duplicate.result[0]?.valuesMap ?? {};
          const hasDuplicate = Object.values(localizations).some(
            (localization) => localization.persisted === true
          );
          if (hasDuplicate) {
            this.$store.commit(
              "SET_ERROR",
              "There is already a localization with the i18nKey " +
                localization.i18nKey +
                " in key " +
                localization.referenceKey +
                " of keySpace " +
                localization.referenceKeySpace
            );
            return true;
          }
        }
      }
      return false;
    },

    handleFocusOut(id) {
      let container = this.$refs[id];
      if (Array.isArray(container)) container = container[0];
      //if the translation containers is about to be hidden,
      //scroll to top to only show the first translation option
      if (getComputedStyle(container).overflow !== "hidden") return;
      container.scrollTop = 0;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    newLocalizationInputRules() {
      return {
        required: (val) => !!val || "Value is required",
      };
    },

    headers() {
      return [
        {
          text: "Key",
          value: "i18nKey",
          class: "i18nKey-header",
          cellClass: "i18nKey-column",
        },
        {
          text: "Area",
          value: "referenceKeySpace",
          class: "referenceKeySpace-header",
        },
        {
          text: "Area Pattern",
          value: "referenceKey",
          class: "referenceKey-header",
        },
        {
          text: "Localizations",
          value: "localizations",
          sortable: false,
          cellClass: "localizations-column",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },

    possibleFilters() {
      const keySpaces = this.keySpaces.map((keySpace) => {
        return {
          text: keySpace,
          value: keySpace,
        };
      });

      const filters = [
        {
          text: "Key",
          property: "i18nKey",
          type: "text",
          operator: "contains",
          operatorOptions: [
            { text: "contains", value: "contains" },
            { text: "is", value: "eq" },
          ],
        },
        {
          text: "Area",
          property: "referenceKeySpace",
          type: "list",
          options: keySpaces,
        },
        {
          text: "Area Pattern",
          property: "referenceKey",
          type: "text",
          operator: "contains",
          operatorOptions: [
            { text: "contains", value: "contains" },
            { text: "is", value: "eq" },
          ],
        },
      ];

      return filters;
    },

    tableHeight() {
      return this.getPageHeight() - 12 - 80 - 12;
    },

    limit() {
      //TODO: Increase limit to 100 after PEAK-15226
      return 20;
    },

    hasChanges() {
      return Object.keys(this.changedLocalizations).length > 0;
    },

    showMinified() {
      return this.getPageWidth() <= 900;
    },
  },
};
</script>

<style scoped>
.localization-overview {
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.localization-overview > div.localization-content {
  display: flex;
  height: 100%;
  padding: 0;
  flex-flow: column;
  width: 100%;
}

.search-toolbar {
  z-index: 8;
}

.localizations-limit-select {
  max-width: 70px;
}

aside.navigation-drawer-extended + div.localization-content {
  width: calc(100% - 400px);
}

.locale-select {
  max-width: 120px;
}

.locale-select.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined::v-deep
  .v-input__append-inner {
  margin-top: 4px;
}

.locale-select.v-text-field--outlined.v-input--dense.v-text-field--outlined::v-deep
  > .v-input__control
  > .v-input__slot {
  min-height: 32px;
}

.localizations-table::v-deep td.localizations-column {
  position: relative;
  min-width: 400px;
  height: 60px;
}

.localizations-table::v-deep .add-localization-btn {
  height: 60px;
  width: 100%;
}

.localizations-table::v-deep
  td.localizations-column
  > .localizations-container {
  z-index: 20;
  overflow: hidden;
  height: 60px;
}

.localizations-table::v-deep tr.localization-changed,
.localizations-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.localization-changed
  > td.localizations-column
  > .localizations-container
  > .i18n-editor
  > .i18n-table:not(:focus-within)
  tr:first-child {
  background: rgba(0, 160, 227, 0.1);
}

.localizations-table::v-deep
  td.localizations-column:not(.disabled)
  > .localizations-container:focus-within {
  height: auto;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  z-index: 9999;
  border: 1px solid rgb(46, 117, 212);
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  outline: none;
  background-color: white;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 0px;
}

.localizations-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover
  > td.localizations-column
  > .localizations-container
  > .i18n-editor
  .i18n-table:not(:focus-within)
  tr:first-child {
  background-color: #eeeeee;
}

.localizations-table::v-deep .value-input {
  border: 1px solid grey;
  flex: 1 1 auto;
  line-height: 20px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  appearance: auto;
  padding: 8px 12px;
}

.localization-overview .loading-overlay {
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  background-color: transparent;
}

.localization-overview .loading-overlay::v-deep .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}

.localization-overview
  .loading-overlay::v-deep
  .v-overlay--active
  .v-overlay__scrim {
  opacity: 0 !important;
}
</style>